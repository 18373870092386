import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

const StyledImage = styled(Plaatjie)`
  height: 100px;
  width: 100%;

  & img {
    object-fit: contain !important;
  }
`

interface CertificationProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_CertificationSmall
}

const CertificationSmall: React.FC<CertificationProps> = ({ fields }) => (
  <section className="container position-relative my-5">
    <div className="row align-items-center align-items-lg-left position-relative">
      <div className="col-lg-6 col-12 mb-5 mb-lg-0">
        <ParseContent content={fields.description} />
      </div>
      {fields?.certification?.map((edge, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <div className="h-100 col-md col-6">
            <StyledImage image={edge?.image} className="mx-auto mx-lg-0" alt="" />
          </div>
        </React.Fragment>
      ))}
    </div>
  </section>
)

export default CertificationSmall
